import Vue from "vue";
import Vuex from "vuex";
import {
  getWalletAddressFromWeb3OrOtherSource,
  getDaiBalanceForAddress,
} from "@/utils/web3Utils";

Vue.use(Vuex);

const loadedLanguage = localStorage.getItem("language") || "en";

const store = new Vuex.Store({
  state: {
    language: loadedLanguage,
    walletAddress: null,
    daiBalance: null,
  },
  mutations: {
    SET_LANGUAGE(state, payload) {
      state.language = payload;
      localStorage.setItem("language", payload);
    },
    setWalletAddress(state, address) {
      state.walletAddress = address;
    },
    setDaiBalance(state, balance) {
      state.daiBalance = balance;
    },
  },
  actions: {
    async fetchWalletAddress({ commit }) {
      // 获取钱包地址逻辑
      const address = await getWalletAddressFromWeb3OrOtherSource();
      commit("setWalletAddress", address);
    },
    async fetchDaiBalance({ commit, state }) {
      // 获取DAI余额逻辑
      const balance = await getDaiBalanceForAddress(state.walletAddress);
      commit("setDaiBalance", balance);
    },
  },
});

export default store;
