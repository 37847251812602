<template>
    <div>
        <h1>ContactNetwork</h1>
        <h2>THIS IS ContactNetwork</h2>
        <ul class="example">
            <li><router-link to="/EvContract">Ev EvStake</router-link></li>

        </ul>
    </div>
</template>

<script>
export default {
    name: "ContactNetwork",
};
</script>

<style lang="less" scoped>
h1 {
    color: red;
}
</style>
