<template>
  <div ref="qrcode"></div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 128,
    },
    height: {
      type: Number,
      default: 128,
    },
  },
  watch: {
    text(newVal) {
      this.generateQRCode(newVal);
    },
  },
  mounted() {
    this.generateQRCode(this.text);
  },
  methods: {
    generateQRCode(text) {
      if (this.qrcode) {
        // this.qrcode.clear(); // 清除旧的二维码
        this.qrcode.makeCode(text); // 生成新的二维码
      } else {
        this.qrcode = new QRCode(this.$refs.qrcode, {
          text: text,
          width: this.width,
          height: this.height,
        });
      }
    },
  },
  data() {
    return {
      qrcode: null,
    };
  },
};
</script>

<style scoped>
/* 可以添加一些自定义样式 */
</style>
