<template>
  <div></div>
</template>

<script>
export default {
  name: "testH",
  methods: {
    fn() {
      console.log("fn");
    },
  },
};
</script>

<style lang="less"></style>
