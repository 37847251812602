import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
import i18n from "../utils/i18n";
// tb合约pg合约方法
export default {
    
    async getLpSharePercentage(currentAddress, ContractAddress, _decimals) {
        try {
    
          let Factory;
          Factory = require("../json/abi/aaveChild.json");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
        
          const balance = await contract.getLpSharePercentage(currentAddress)
          console.log(balance)
          // ethers转json
          var datas
      datas = big(balance.toString())
        .div(10 ** 0)
        .toFixed();
          return {code: "0", data: datas};
        } catch (error) {
           console.log(error);
        }
      },

  async getAmounts(currentAddress, ContractAddress, _decimals,value,value2,value3) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const amountApproved = big(value)
        .times(10 ** 18)
        .toFixed();
        const amountApproved2 = big(value2)
        .times(10 ** 18)
        .toFixed();
        const amountApproved3 = big(value3)
        .times(10 ** 18)
        .toFixed();
      const balance = await contract.getAmounts(amountApproved,amountApproved2,amountApproved3)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }


      newData[0] = big(newData[0])
        .div(10 ** 18)
        .toFixed();
        newData[1] = big(newData[1])
        .div(10 ** 18)
        .toFixed();
        newData[2] = big(newData[2])
        .div(10 ** 18)
        .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
      //  console.log(error);
    }
  },
  async getRemoveLiquidityAmountOut(currentAddress, ContractAddress, _decimals,token1,token2,value) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
    //   const amountApproved = big(value)
    //     .times(10 ** 18)
    //     .toFixed();
     
      const balance = await contract.getRemoveLiquidityAmountOut(token1,token2,value)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }

    newData.tokenAAmount = big(newData.tokenAAmount)
        .div(10 ** 6)
        .toFixed();
        newData.tokenBAmount = big(newData.tokenBAmount)
        .div(10 ** 18)
        .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
       console.log(error);
    }
  },
  async getAddLiquidityAmountOut(currentAddress, ContractAddress, _decimals,tokenA,tokenB,value) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const amountApproved = big(value)
        .times(10 ** _decimals)
        .toFixed();
      const balance = await contract.getAddLiquidityAmountOut(tokenA,tokenB,amountApproved)
      console.log(balance)
      // ethers转json
      
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();


    //   newData[0] = big(newData[0])
    //     .div(10 ** 18)
    //     .toFixed();
    //     newData[1] = big(newData[1])
    //     .div(10 ** 18)
    //     .toFixed();


      return {code: "0", data: datas};
    } catch (error) {
      //  console.log(error);
    }
  },
  async lpUsers(currentAddress, ContractAddress, _decimals,token) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
    //   const amountApproved = big(value)
    //     .times(10 ** _decimals)
    //     .toFixed();
      const balance = await contract.lpUsers(token,currentAddress)
      console.log(balance)
      // ethers转json
      
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }


    //   newData.lpAmount = big(newData.lpAmount)
    //     .div(10 ** 18)
    //     .toFixed();
        newData.interestFalconAmount = big(newData.interestFalconAmount)
        .div(10 ** 18)
        .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
       console.log(error);
    }
  },
  async pendingReward(currentAddress, ContractAddress, _decimals,token) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
    //   const amountApproved = big(value)
    //     .times(10 ** _decimals)
    //     .toFixed();
      const balance = await contract.pendingReward(currentAddress)
      console.log(balance)
      // ethers转json
      
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();



      return {code: "0", data: datas};
    } catch (error) {
       console.log(error);
    }
  },
  // 存入
  async depositToken(tokenAddress, amountADesired, CurrentAccount, _decimals, swap_Aaddress,aave) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.depositToken(aave,swap_Aaddress, amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .depositToken(aave,swap_Aaddress, amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  async addLiquidity(tokenAddress, amountADesired, CurrentAccount, _decimals, value2,_decimals2) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
        const amountApproved2 = big(value2)
        .times(10 ** _decimals2)
        .toFixed();

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.addLiquidity(amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .addLiquidity(amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  async removeLiquidity(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


    //   const amountApproved = big(amountADesired)
    //     .times(10 ** _decimals)
    //     .toFixed();
       

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.removeLiquidity(amountADesired); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .removeLiquidity(amountADesired)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  async withdraFalcon(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {

      let Factory;
      Factory = require("../json/abi/aaveChild.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
       

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.withdraFalcon(); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .withdraFalcon()
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  
};
