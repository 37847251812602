import Web3 from "web3";
import big from "big.js";
import { ethers } from "ethers";
// tb合约pg合约方法
export default {
//
async _whitesAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/loanapi.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract._whitesAddress(currentAddress)


      return { code: "0", data: balance };
    } catch (error) {
      //  console.log(error);
    }
  },
  //
  async isStart(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/loanapi.json");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.isStart()



      return { code: "0", data: balance };
    } catch (error) {
      //  console.log(error);
    }
  },
  async _flashAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/loanapi.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract._flashAddress(currentAddress)



      return { code: "0", data: balance };
    } catch (error) {
      //  console.log(error);
    }
  },

};
