import Web3 from "web3";
import big from "big.js";
import { ethers } from "ethers";

export default {
    //
async userCalls(currentAddress, ContractAddress, _decimals) {
  try {
    //   let position = "";
    // console.log(_decimals)
    let Factory;
    Factory = require("../json/abi/Falcon2api.json");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
    const balance = await contract.userCalls(currentAddress)

    var datas
    datas = big(balance.toString())
      .div(10 ** 0)
      .toFixed();

    return { code: "0", data: datas };
  } catch (error) {
     console.log(error);
  }
},
    //
    async lastcalltime(currentAddress, ContractAddress, _decimals) {
      try {
        //   let position = "";
        // console.log(_decimals)
        let Factory;
        Factory = require("../json/abi/Falcon2api.json");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
        const balance = await contract.lastCallTime(currentAddress)

        var datas
        datas = big(balance.toString())
          .div(10 ** 0)
          .toFixed();

        return { code: "0", data: datas };
      } catch (error) {
         console.log(error);
      }
    },
  //
async v2proAddress(currentAddress, ContractAddress, _decimals) {
  try {
    //   let position = "";
    // console.log(_decimals)
    let Factory;
    Factory = require("../json/abi/Falcon2api.json");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
    const balance = await contract.v2proAddress()



    return { code: "0", data: balance };
  } catch (error) {
     console.log(error);
  }
},
//
async childrenUserAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon2api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.childrenUserAddress(currentAddress,3)



      return { code: "0", data: balance };
    } catch (error) {
      //  console.log(error);
    }
  },
  async users(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon3api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.users(currentAddress)
      console.log(balance)
      // ethers转json
      let data=balance
      let newData = {};
for (const key in data) {
  if (data.hasOwnProperty(key)) {
    if (typeof data[key] === 'object' && data[key]._isBigNumber) {
      newData[key] = data[key].toString();
    } else {
      newData[key] = data[key];
    }
  }
}

    newData.daiAmount = big(newData.daiAmount)
    .div(10 ** 18)
    .toFixed();
    newData.falconAmount = big(newData.falconAmount)
        .div(10 ** 18)
        .toFixed();




      return { code: "0", data: newData };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async usersv2(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon2+api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.users(currentAddress)
      console.log(balance)
      // ethers转json
      let data=balance
      let newData = {};
for (const key in data) {
  if (data.hasOwnProperty(key)) {
    if (typeof data[key] === 'object' && data[key]._isBigNumber) {
      newData[key] = data[key].toString();
    } else {
      newData[key] = data[key];
    }
  }
}

    newData.daiAmount = big(newData.daiAmount)
    .div(10 ** 18)
    .toFixed();
    newData.falconAmount = big(newData.falconAmount)
        .div(10 ** 18)
        .toFixed();




      return { code: "0", data: newData };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async getMyUserMatic(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon3api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserMatic()
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return { code: "0", data: datas };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async getMyUserMaticv2(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon2+api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserMatic(currentAddress)
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return { code: "0", data: datas };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async getMyUserDaiInterest(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon3api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserDaiInterest()
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return { code: "0", data: datas };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async getMyUserDaiInterestv2(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon2+api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserDaiInterest(currentAddress)
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return { code: "0", data: datas };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async getMyUserFalcon(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon3api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserFalcon()
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return { code: "0", data: datas };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },
  async getMyUserFalconv2(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon2+api.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserFalcon(currentAddress)
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return { code: "0", data: datas };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    }
  },

  // 自动
  async deposit(
    addresscode,
    ContractAddress,
    _decimals,
    dai,
    falcon,
    matic
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon3api.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)


      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.deposit(web3.utils.toWei(dai, 'ether'),web3.utils.toWei(falcon, 'ether'),web3.utils.toWei(matic, 'ether')); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice=await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      var hexValuedata={}
      if(matic>0){
        hexValuedata={
            value:web3.utils.toWei(matic, 'ether'),
            from: addresscode,
            to: ContractAddress,
            data: approveFunction.encodeABI(),
          }
      }else{
        hexValuedata={
            from: addresscode,
            to: ContractAddress,
            data: approveFunction.encodeABI(),
          }
      }
      const hexValue = await approveFunction.estimateGas(hexValuedata);
        console.log(hexValue,'1111111111111111');
        const hexValueto = hexValue.toString();

        console.log(Math.round(hexValueto*1.5),'22222222222222222')
      var senddata={}
      if(matic>0){
        senddata={
            value:web3.utils.toWei(matic, 'ether'),
            from: addresscode,
            gasLimit: Math.round(hexValueto*1.5),
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto*1.5),
        }
      }else{
        senddata={
            from: addresscode,
            gasLimit: Math.round(hexValueto*1.5),
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto*1.5),
        }
      }
      await tokebContract.methods
        .deposit(web3.utils.toWei(dai, 'ether'),web3.utils.toWei(falcon, 'ether'),web3.utils.toWei(matic, 'ether'))
        .send(senddata);

      return { code: "0" };
    } catch (error) {
        let errorMessage = "Unknown error";
          let errorData = {};
          let errorCode = "1";
          // 检查 error 对象并提取详细的错误信息
          if (error.message) {
            errorMessage = error.message;
          }

          if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
          }

          if (error.data) {
            errorData = error.data;
            if (error.data.code) {
              errorCode = error.data.code;
            }
          }

          return {
            code: errorCode,
            error: {
              message: errorMessage,
              data: errorData,
            },
          };
      //    console.log(error);
    }
  },

// 启动
async withdrawAll(
    addresscode,
    ContractAddress,
    _decimals,

  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falcon3api.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)


      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.withdrawAll(); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice=await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      });
      const hexValueto = hexValue.toString();

      await tokebContract.methods
        .withdrawAll()
        .send({
            from: addresscode,
            gasLimit: Math.round(hexValueto*1.5),
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto*1.5),
        });

      return { code: "0" };
    } catch (error) {
        let errorMessage = "Unknown error";
          let errorData = {};
          let errorCode = "1";
          // 检查 error 对象并提取详细的错误信息
          if (error.message) {
            errorMessage = error.message;
          }

          if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
          }

          if (error.data) {
            errorData = error.data;
            if (error.data.code) {
              errorCode = error.data.code;
            }
          }

          return {
            code: errorCode,
            error: {
              message: errorMessage,
              data: errorData,
            },
          };
      //    console.log(error);
    }
  },
// 启动
async withdrawOne(
  addresscode,
  ContractAddress,
  _decimals,
  dai,
    matic,
    falcon,
    daiInterest
) {
  try {
    //   let position = "";
    //  console.log(_decimals)
    let Factory;
    Factory = require("../json/abi/Falcon3api.json");
    const ethereum = window.ethereum;
    const web3 = new Web3(Web3.givenProvider || ethereum);
    // 创建合约对象
    //    console.log(Factory.abi)


    //   console.log(amountApproved)
    const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

    // console.log(tokebContract)
    const approveFunction = tokebContract.methods.withdrawOne(web3.utils.toWei(dai, 'ether'),web3.utils.toWei(daiInterest, 'ether'),web3.utils.toWei(falcon, 'ether'),web3.utils.toWei(matic, 'ether')); // 替换为您要授权的代币数量
    //  console.log(approveFunction)
    // gas
    const getGasPrice=await web3.eth.getGasPrice()
    const getGasPriceto = getGasPrice.toString();
    //  预估
    const hexValue = await approveFunction.estimateGas({
      from: addresscode,
      to: ContractAddress,
      data: approveFunction.encodeABI(),
    });
    const hexValueto = hexValue.toString();

    await tokebContract.methods
      .withdrawOne(web3.utils.toWei(dai, 'ether'),web3.utils.toWei(daiInterest, 'ether'),web3.utils.toWei(falcon, 'ether'),web3.utils.toWei(matic, 'ether'))
      .send({
          from: addresscode,
          gasLimit: Math.round(hexValueto*1.5),
          to: ContractAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto*1.5),
      });

    return { code: "0" };
  } catch (error) {
      let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
          errorCode ="-1";
          errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
    //    console.log(error);
  }
},


};
