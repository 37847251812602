<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {

  }
};
</script>

<style lang="less" scoped>
h1 {
  color: red;
}
</style>
