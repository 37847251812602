<template>
    <div class="SelectMainStyle">
        <div class="tips">
            <h1>Web3 Wallet</h1>
            <p>{{ $t('network.ev') }}</p>
            <button class="btnConnect">Connect Wallet</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EvEnvironment',
    methods: {
    }
}
</script>

<style lang="less">
.SelectMainStyle {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    background: url('../assets/img/wallet.png') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    backdrop-filter: blur(10px);
    padding-bottom: 40px;

    .tips {
        display: flex;
        flex-direction: column;
        width: 300px;
        border-radius: 22px;
        background-color: rgba(52, 47, 47, 0.8);
        backdrop-filter: blur(5px);
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 20px;

        h1,
        p {
            color: white;
            font-family: 'Poppins', sans-serif;
        }

        h1 {
            font-size: 32px;
        }

        p {
            font-size: 16px;
            padding: 20px;
            line-height: 20px;
        }

        .btnConnect {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            width: 200px;
            height: 57px;
            border-radius: 22px;
            border: 0;
            font-size: 17px;
            background: url('../assets/img/Gradient.svg') no-repeat center center/cover;
            cursor: pointer;
            margin: 10px 0;
        }

        .btnConnect:active {
            transform: scale(0.98);
        }
    }
}
</style>

