<template>
    <div>
      <div class="nav" style="margin-bottom: 10px;">
          <ul>
            <li>
              <div class="nav_c " :class="{nav_c_type:navtype==1}" @click="onnav(1)">
                  {{ $t("stake.Stake") }}
              </div>

            </li>
            <li>
              <div class="nav_c" :class="{nav_c_type:navtype==2}" @click="onnav(2)">
                  {{  $t("Withdraw.Withdraw") }}
              </div>
            </li>

          </ul>
        </div>
    <div class="stakewait">
      <!-- notification start -->
      <div class="marquee">
        <Marquee :duration="15" v-if="falconAddresstype">{{ promptvalue }}</Marquee>
      </div>

      <!-- notification end -->
      <div class="coinInfomation">
        <div class="coinImg"><img :src="falocn" class="dailogo"/></div>
        <div class="coinInfo">Falcon Liquidity</div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
      <div v-if="navtype==1">

      <div class="ownwalletInfo">
        <div class="ownTips">USDT:</div>
        <div class="ownMoney">
          {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
      <div class="inputInfo">
        <!-- @input="validateAmount" -->
        <input v-model.number="daiValue" type="number" class="inputStyle" @input="oninout"
               style="width: 58.66667vw;"/>
        <button @click="(daiValue = formattedDaiOwnMoney), oninout()" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">FALCON:</div>
        <div class="ownMoney">
          {{ formattedFalconbalance }}<img class="swap_falcon" :src="D2"/>
        </div>
      </div>
      <div class="inputInfo">
        <input v-model.number="daiValue2" type="number" class="inputStyle" disabled style="width: 100%;"/>
        <!-- <button @click="(daiValue2 = formattedFalconbalance)" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button> -->
      </div>


      <div class="StakeAuthor" style="display: flex;justify-content: space-between;">

        <div class="Author" v-if="empowerbutton==false" style="width: 45%;">
        <button :disabled="empowerbutton"  class="StakeBtn ripple" @click="empower"
          v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }}USDT
        </button>
        <button class="StakeBtn" v-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <!-- appoove button end -->
      </div>
      <div class="Author" v-else-if="empowerbuttonfalcon==false" style="width: 45%;">
        <button :disabled="empowerbuttonfalcon"  class="StakeBtn ripple" @click="empower2"
          v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }}falcon
        </button>
        <button class="StakeBtn" v-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <!-- appoove button end -->
      </div>
      <div class="Author" v-else style="width: 45%;">
        <button disabled class="StakeBtn ripple">
          {{ $t("stake.Appoove") }}
        </button>
      </div>

        <div class="Author" style="width: 45%;">
        <button class="StakeBtn ripple" v-if="daiValue<=0" disabled>{{ $t("stake.Stake") }}</button>
        <button  class="StakeBtn ripple" disabled v-else-if="empowerbutton==false||empowerbuttonfalcon==false">
          {{ $t("stake.Stake") }}
        </button>
        <button  class="StakeBtn " @click="addLiquidity()" v-else-if="typedeposit == 0">
          {{ $t("stake.Stake") }}
        </button>
        <button class="StakeBtn" v-else>{{ $t("stake.Stakeing") }}</button>
      </div>
      </div>
    </div>
    <div v-if="navtype==2">
      <div class="ownwalletInfo">
        <div class="ownTips">LP:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
          <!-- {{ lpUsersdata.lpAmount  }}
          <img class="daimi" :src="swap_usdt"/> -->
           <!-- <span>LP</span> -->

            {{ getLpSharePercentagedata/10000 | fildecimals3}}%

        </div>
      </div>
    <!-- <div class="inputInfo">
      <input v-model.number="falvalue1" type="number" class="inputStyle" style="width: 58.66667vw;"/>
      <button @click="(falvalue1 =  Math.floor(lpUsersdata.lpAmount))" class="tbnStyle ripple">
        {{ $t("network.all") }}
      </button>
    </div> -->
    <div v-if="lpUsersdata.lpAmount>0">
    <div class="slider" >
        <van-slider :disabled="sliderdisabled" v-model="slidervalue" :step="1" :max="100" @change="onChange"
          bar-height="10px" active-color="#DFBEF3">
          <div slot="button" class="custom-button">
            {{ slidervalue }}%
          </div>
        </van-slider>
      </div>
      <div class="" v-if="slidervalue>0">
      <div class="ownwalletInfo">
        <div class="ownTips">USDT:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
          {{ getRemoveLiquidityAmountOutvalue.tokenAAmount | fildecimals }}
          <img class="daimi" :src="swap_usdt"/>
           <!-- <span>LP</span> -->
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">FALCON:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
            {{ getRemoveLiquidityAmountOutvalue.tokenBAmount | fildecimals }}
          <img class="daimi" :src="D2"/>
           <!-- <span>LP</span> -->
        </div>
      </div>
    </div>
</div>
    <div class="WithDrawBtn" >
          <button class="tbnStyle2 ripple" @click="removeLiquidity()"
          style="width: 100%;"
                  v-if="slidervalue>0">
            {{ $t("Withdraw.Withdraw") }}
          </button>
          <button class="StakeBtn ripple" disabled v-else  style="width: 100%;">
            {{ $t("Withdraw.Withdraw") }}
          </button>
        </div>

  <!-- <div class="time_c" >
   {{lpUsersdata.interestFalconAmount | fildecimals}}<img class="daimi" :src="swap_usdt"/>
  </div> -->
  <div class="ownwalletInfo">
        <div class="ownTips">FALCON:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
          {{ pendingRewarddata | fildecimals2 }}
          <img class="daimi" :src="D2"/>
           <!-- <span>LP</span> -->
        </div>
      </div>
      <div class="WithDrawBtn" >
          <button class="tbnStyle2 ripple" @click="withdraFalcon()"
          style="width: 100%;"
                 >
                 {{ $t("Withdraw.Getreward") }}
          </button>
          <!-- <button class="tbnStyle1 ripple" disabled v-else  style="width: 100%;">
            {{ $t("Withdraw.Withdraw") }}
          </button> -->
        </div>


   </div>
      <div><img class="bStyle" :src="stylea" alt=""/></div>
      <!--loading start-->
      <div v-if="isloading" class="loading">
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
      <!--loading start-->
      <div v-if="isloading2" class="loading">
        <div class="tips">正在寻找套利机会</div>
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
    </div>
  </div>
  </template>
  <style scoped src="@/assets/css/EvFalocn2pro.css"></style>
  <style lang="less" scoped>
  .time_c{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
  }
  .time_c img{
    margin-left: 10px;
  }

.ownwalletInfo {
  font-family: "Poppins", sans-serif;
  display: flex;
}
.ownwalletInfo .ownTips {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
.ownwalletInfo .ownMoney {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.ownwalletInfo .ownMoney img.daimi {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.ownwalletInfo .ownMoney img.swap_falcon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}
.slider {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-button {
  width: 25px;
  height: 25px;
  line-height: 21px;
  background-color: rgb(172, 7, 114);
  color: #fff;
  border: 2px solid white;
  border-radius: 50px;
  text-align: center;
  font-size: 10px;
}

  </style>
  <script>
  import swap_usdt from "@/assets/img/swap_usdt.svg";
  import ProgressBar from "@/components/ProgressBar.vue";
  import Marquee from "@/components/Marquee.vue";
  import web3Utils from "@/utils/web3Utils.js";
  import dai from "@/assets/img/DaiStablecoin.svg";
  import D1 from "@/assets/img/d1.svg";
  import D2 from "@/assets/img/d2.svg";
  import D3 from "@/assets/img/d3.svg";
  import loadingimg from "@/assets/img/loading.svg";
  import FG1 from "@/assets/img/FG1.svg";
  import falocn from "@/assets/img/FalconLogoNew.svg";
  import daimni from "@/assets/img/daimini.svg";
  import swap_falcon from "@/assets/img/swap_falcon.svg";
  import swap_matic from "@/assets/img/swap_matic.svg";
  import stylea from "@/assets/img/stylea.png";
  import contract from "@/plugins/contract.js";
  import siteapi from "@/json/siteapi.json";
  import Plugens from "@/plugins/index";
  import Falocnn from "@/plugins/Falocnn.js";
  import Falcon2js from "@/plugins/Falcon2js.js";
  import Web3 from "web3";
  import axios from "axios";
  import sign from "@/plugins/sign.js";
  import aaveChild from "@/plugins/aaveChild";
  import Vue from "vue";
import { Slider } from 'vant';
Vue.use(Slider);
import big from "big.js";
  export default {
    data() {
      return {
        swap_usdt,
        isButtonDisabled: true,
        depositDisabled: true,
        typeApprove: 0,
        typedeposit: 0,
        dai: dai,
        FG1: FG1,
        falocn: falocn,
        daimni: daimni,
        swap_falcon: swap_falcon,
        swap_matic: swap_matic,
        loadingimg: loadingimg,
        daiValue: '',
        daiValue2: 0,
        daiValue3: 0,
        D1: D1,
        D2: D2,
        D3: D3,
        daiOwnMoney: 0,
        stylea: stylea,
        stakedMoney: 0,
        currentProgress: 0,
        usersdata: {},
        sitedata: {},
        addresscode: "",
        empowerbutton: true,
      empowerbutton2: true,
      empowerbuttonfalcon:true,
        needinvest: "",
        Minimal: 0,
        isloading: false,
        isloading2: false,
        falconAddresstype: false,
        isOpentype: false,
        flashAddresstype: true,
        returndatadata: "",
        promptvalue: "",
        honorLeave: "",
        Falconall: false,
        Falconbalance: 0,
        getMaticBalancevalue: 0,
        falconuserdata: {
          daiAmount: 0,
          falconAmount: 0
        },
        maticvalue: 0,
        getMyUserFalconvalue: 0,
        interestvalue: 0,
        Falconv2pro: "",
        isV2: false,
        navtype:1,
        falvalue1:0,
        falvalue2:0,
        falvalue3:0,
        falvalue4:0,
        balanceusdt:0,
        usdtAmount:0,
        falconAmount:0,
        pickGetMyUserFalconvalue:0,
        pickGetMyUserDaiInterestvalue:0,
        endTime: null,
        countdown: '',
        lastcalltimevaluetype:false,
        childContractAddressvalue:"",
        lpUsersdata:{},
        pendingRewarddata:"",
        sliderdisabled: false,
        slidervalue: 0,
        getLpSharePercentagedata:"",
        getRemoveLiquidityAmountOutvalue:{
            tokenAAmount:0,
            tokenBAmount:0
        }
      };
    },
    components: {
      Marquee,
      //ProgressBar,
    },
    async mounted() {

      // Determine whether the wallet is connected
      if (typeof window.ethereum !== "undefined") {
        //console.log("Connected wallet");

        // Async obtaining network information
        window.ethereum
          .request({method: "eth_chainId"})
          .then((chainId) => {
            // is Polygon?
            if (chainId === "0x89") {
              // console.log("The current network is a Polygon chain");
              // this.$router.push({path:'/EvStake'});
            } else {
              //  console.log("The current network is not Polygon chain");
              this.$router.push({path: "/"});
              return;
            }
          })
          .catch((error) => {
            console.error(
              "Failed to obtain network information. Procedure:",
              error
            );
          });
      } else {
        //console.log("Unconnected wallet");
      }
      this.sitedata = siteapi;
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        // this.daiOwnMoney = parseFloat(
        //   await web3Utils.getDaiBalance(this.addresscode)
        // );



        this.signtype();
        // this.exchangeallowance()
      }
    },
    filters: {

      fildecimals(e) {
        const multipliedNumber = e * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        //    console.log(result);

        let value = parseFloat(result);
        return value;
      },
      fildecimals2(e) {
        const multipliedNumber = e * 100000000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000000;
        //    console.log(result);

        let value = parseFloat(result);
        return value;
      },
      fildecimals3(e) {
        console.log(e)
        const multipliedNumber = e * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //    console.log(result);

        let value = parseFloat(result);
        return value;
      },
    },
    methods: {
        funlastcalltime(){
            const date = this.lastcalltimevalue*1000

console.log(date,'22222222222222222222',this.lastcalltimevalue*1000*24)
// 用法示例

const storedEndTime = date + (1 * 24 * 60 * 60) * 1000;
console.log(storedEndTime, 'aaaaaaaaaaaaa')
// localStorage.getItem('endTime');
if (storedEndTime) {
  this.endTime = parseInt(storedEndTime, 10);
} else {
    console.log('asdasdasdasd')
  this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
}
this.updateCountdown(date);
// setInterval(this.updateCountdown, 1000);
this.timer = setInterval(() => {
  //需要定时执行的代码
  this.updateCountdown(date)
}, 1000)
        },
        startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
        updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.finishshow = true
          clearInterval(this.timer)
        }
      }



      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if(this.pad(hours)==null){
        this.countdown=0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
      // 签名验证
      async signtype() {
        this.isloading = true;
        await sign.getauthorization(this.addresscode).then((res) => {

          if (res.code == 0) {
            this.burnContractAddress()

            this.balance()
            this.balance2()


          }
          this.isloading = false;
        });
      },
      onChange(e) {
      console.log(this.slidervalue)
    this.getRemoveLiquidityAmountOut()
    },
    async getRemoveLiquidityAmountOut() {

        console.log(this.lpUsersdata.lpAmount)
        const number = new big(this.lpUsersdata.lpAmount);
const percentage = new big(this.slidervalue);

// 计算 156 的 15%：number * (percentage / 100)
const result = number.times(percentage.div(100));
console.log(result.toFixed(0),'111111111111111111'); // 输出：23.4
var value1=result.toFixed(0)
console.log(value1)
let _decimals = 18;
let ContractAddress;
//console.log('User information')
ContractAddress = this.childContractAddressvalue
await aaveChild.getRemoveLiquidityAmountOut(
  this.addresscode,  //Current user address
  ContractAddress,  //Call contract
  _decimals,
  this.sitedata.USDT_swap.address,
  this.sitedata.falcon_swap.address,
  value1
).then((res) => {
  console.log(res, 'getRemoveLiquidityAmountOut');
this.getRemoveLiquidityAmountOutvalue=res.data
});
},

    async getLpSharePercentage() {
//console.log(this.sitedata)
let _decimals = 18;
let ContractAddress;
//console.log('User information')
ContractAddress = this.childContractAddressvalue
await aaveChild.getLpSharePercentage(
  this.addresscode,  //Current user address
  ContractAddress,  //Call contract
  _decimals,
).then((res) => {
  console.log(res, 'getLpSharePercentage');
this.getLpSharePercentagedata=res.data
});
},
      onnav(e){
        this.daiValue=0
          this.daiValue2=0
          this.daiValue3=0
          this.falvalue1=0
              this.falvalue2=0
              this.falvalue3=0
              this.falvalue4=0
              if(e==1){
                this.navtype=1
              }else if(e==2){
                this.navtype=2
                this.lpUsers()
                this.pendingReward()
                // if(this.falconuserdata.daiAmount > 0 || this.maticvalue > 0 || this.getMyUserFalconvalue > 0 || this.interestvalue > 0){
                //     this.navtype=2
                // }
              }


      },
      async burnContractAddress() {

//console.log(this.sitedata)
let _decimals = 18;
let ContractAddress;
//console.log('User information')
ContractAddress = this.sitedata.aave_token.address;
await contract.burnContractAddress(
  this.addresscode,  //Current user address
  ContractAddress,  //Call contract
  _decimals
).then((res) => {
  console.log(res, 'childContractAddress');
  this.childContractAddressvalue = res.data
  this.getLpSharePercentage()

});
},
      oninout(){
        if(this.daiValue>0){
            this.getAddLiquidityAmountOut()
        }else{
            this.daiValue2=0
        }

      },
      async getAddLiquidityAmountOut() {
//console.log(this.sitedata)
let _decimals = 6;
let ContractAddress;
//console.log('User information')
ContractAddress = this.childContractAddressvalue
await aaveChild.getAddLiquidityAmountOut(
  this.addresscode,  //Current user address
  ContractAddress,  //Call contract
  _decimals,
  this.sitedata.USDT_swap.address,
  this.sitedata.falcon_swap.address,
  this.daiValue
).then((res) => {
  console.log(res, 'getAddLiquidityAmountOut');
this.daiValue2= parseFloat((res.data*1).toFixed(4))
 this.exchangeallowance()
 this.exchangeallowancefalcon()
});
},
async lpUsers() {
//console.log(this.sitedata)
let _decimals = 18;
let ContractAddress;
//console.log('User information')
ContractAddress = this.childContractAddressvalue
await aaveChild.lpUsers(
  this.addresscode,  //Current user address
  ContractAddress,  //Call contract
  _decimals,
  this.sitedata.USDT_swap.address,

).then((res) => {
  console.log(res, 'lpUsers');
    this.lpUsersdata=res.data
});
},
async pendingReward() {
//console.log(this.sitedata)
let _decimals = 18;
let ContractAddress;
//console.log('User information')
ContractAddress = this.childContractAddressvalue
await aaveChild.pendingReward(
  this.addresscode,  //Current user address
  ContractAddress,  //Call contract
  _decimals,
  this.sitedata.USDT_swap.address,

).then((res) => {
  console.log(res, 'pendingReward');
    this.pendingRewarddata=res.data
});
},
      async exchangeallowance() {
        console.log('usdt数量')
      let _decimals = 6;
      let spender;
      let Address;
      Address =this.sitedata.USDT_swap.address;
      spender = this.childContractAddressvalue
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result,'usdt数量')
        this.isloading = false;

        if (result.data * 1 >= this.daiValue) {
          this.empowerbutton = true;
        } else {
          this.empowerbutton = false;
        }
        // if (this.daiValue <= result.data * 1) {
        //   this.depositDisabled = false;
        // } else {
        //   this.depositDisabled = true;
        // }
      });
    },
    async exchangeallowancefalcon() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address
      spender = this.childContractAddressvalue
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result,'falcon数量')
        this.isloading = false;
        if (result.data * 1 >= this.daiValue2) {
          this.empowerbuttonfalcon = true;
        } else {
          this.empowerbuttonfalcon = false;
        }

      });
    },
    empower() {
      this.isloading = true;  // open loading
      this.Approve();
    },

    // contract Approve
    async Approve() {
      let _decimals = 6;
      let Address;
      let spender;
      Address = this.sitedata.USDT_swap.address;
      spender = this.childContractAddressvalue
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens2(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbutton = true;

            // this.userIsActive()
          } else {

            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    async empower2(){
      this.isloading = true;
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.falcon_swap.address
      spender = this.childContractAddressvalue
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens2(
          Address, //
          this.daiValue2, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbuttonfalcon = true;

            // this.userIsActive()
          } else {

            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    async addLiquidity() {
        this.isloading = true;
// dai  _decimals=18
// usdt _decimals=6
let _decimals = 6
let Address;
Address = this.childContractAddressvalue
this.typedeposit = 1;
try {
  await aaveChild
    .addLiquidity(
      Address, //Contract address
      this.daiValue, //quantity
      this.addresscode, //Current address
      _decimals, //Fractional part
      this.daiValue2,
      18
    )
    .then((res) => {
      console.log(res);
      this.typedeposit = 0;
      this.isloading = false;
      if (res.code == 0) {
        this.balance()
        this.balance2()
        this.lpUsers()
        this.pendingReward()
        this.burnContractAddress()
        this.typedeposit = 0;
        this.$Notice.success({
          title: this.$t("stake.StakeSuccessful")
          //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
        });


      } else {

        this.isloading = false;
        if (res.code < 1) {
          this.$Notice.warning({
            title: res.error.message
          });
        } else {
          this.$Notice.warning({
            title: this.$t("stake.StakeFailed")
            // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
          });
        }
      }
    });
} catch (error) {

  this.$Notice.warning({
    title: this.$t("stake.StakeFailed")
    //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
  });
  // console.log(error);
}
},
async removeLiquidity() {
    this.isloading = true;
    const number = new big(this.lpUsersdata.lpAmount);
const percentage = new big(this.slidervalue);

// 计算 156 的 15%：number * (percentage / 100)
const result = number.times(percentage.div(100));
console.log(result.toFixed(0),'111111111111111111'); // 输出：23.4
var value1=result.toFixed(0)
console.log(value1)

let _decimals = 18
let Address;
Address = this.childContractAddressvalue
this.typedeposit = 1;
try {
  await aaveChild
    .removeLiquidity(
      Address, //Contract address
      value1, //quantity
      this.addresscode, //Current address
      _decimals, //Fractional part

    )
    .then((res) => {
      console.log(res);
      this.typedeposit = 0;
      this.isloading = false;
      if (res.code == 0) {
        this.lpUsers()
        this.pendingReward()
        this.burnContractAddress()
        this.typedeposit = 0;
        this.slidervalue=0
        this.$Notice.success({
          title: this.$t("Withdraw.successful")
          //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
        });

      } else {

        this.isloading = false;
        if (res.code < 1) {
          this.$Notice.warning({
            title: res.error.message
          });
        } else {
          this.$Notice.warning({
            title: this.$t("Withdraw.unsuccessfule")
            // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
          });
        }
      }
    });
} catch (error) {

  this.$Notice.warning({
    title: this.$t("Withdraw.unsuccessfule")
    //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
  });
  // console.log(error);
}
},
async withdraFalcon() {
    this.isloading = true;

let _decimals = 18
let Address;
Address = this.childContractAddressvalue
this.typedeposit = 1;
try {
  await aaveChild
    .withdraFalcon(
      Address, //Contract address
      this.falvalue1, //quantity
      this.addresscode, //Current address
      _decimals, //Fractional part

    )
    .then((res) => {
      console.log(res);
      this.typedeposit = 0;
      this.isloading = false;
      if (res.code == 0) {
        this.lpUsers()
        this.pendingReward()
        this.typedeposit = 0;
        this.$Notice.success({
          title: this.$t("Withdraw.successful")
          //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
        });

      } else {

        this.isloading = false;
        if (res.code < 1) {
          this.$Notice.warning({
            title: res.error.message
          });
        } else {
          this.$Notice.warning({
            title:  this.$t("Withdraw.unsuccessfule")
            // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
          });
        }
      }
    });
} catch (error) {

  this.$Notice.warning({
    title:  this.$t("Withdraw.unsuccessfule")
    //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
  });
  // console.log(error);
}
},
      async pickWithdraw() {
        this.isloading = true;
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address
        try {
          await contract.pickWithdraw(
            this.addresscode,
            ContractAddress,
            _decimals,
            this.falvalue1,
            this.falvalue2,
            this.falvalue3,
          ).then((res) => {
            console.log(res, "pickWithdraw");
            if (res.code == 0) {
              this.isloading = false;

              this.falvalue1=0
              this.falvalue3=0
              this.users();
              this.limitAllInvestAmount();
              this.minInvestAmount();
              this._falconAddress();
              this.isOpen();
              this.balance()
              this.balance2()
              this.onMATIC()
              this.v2proAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }
          });
        } catch (error) {
          console.log(error)
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },

      async falconuser() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.Falconv2pro;
        await Falcon2js.usersv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "falconuser");
          if (res.code == 0) {
            this.falconuserdata = res.data
            this.isV2 = res.data.isV2
          } else {
            this.falconuserdata = {
              daiAmount: 0,
              falconAmount: 0
            }
          }
          console.log(this.falconuserdata, 'falconuserfalconuser')
        });

        await Falcon2js.getMyUserMaticv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "getMyUserMaticv2");
          this.maticvalue = res.data
        });
        await Falcon2js.getMyUserDaiInterestv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "getMyUserDaiInterestv2");
          this.interestvalue = res.data
        });
        await Falcon2js.getMyUserFalconv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "getMyUserFalconv2");
          this.getMyUserFalconvalue = res.data
        });
      },




      validateAmount() {

        if (this.daiValue * 1 < 1000) {
          // this.isButtonDisabled = true;
          // this.depositDisabled = true;
        } else {
          // this.isButtonDisabled = false;
          // this.depositDisabled = false;
          this.daiValue = 1000
          this.exchangeallowance();
        }
      },
      // Falcon余额
      async balance() {
        var that = this
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.Falcon_token.address;
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
          _decimals
        ).then((result) => {
          console.log(result, "Falcon余额");
          this.Falconbalance = result.data

        });
      },
       // usdt余额
       async balance2() {
        var that = this
        let _decimals = 6;
        let spender;
        let Address;
        Address = this.sitedata.USDT_swap.address
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,

          _decimals
        ).then((result) => {
          console.log(result, "usdt余额");
          this.balanceusdt=result.data
          this.isloading = false;

        });
      },
      // Authorization button


      // View the number of authorized contracts

      // pickDeposit button
      async pickDeposit() {
        this.isloading = true;
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address
        try {
          await contract.pickDeposit(
            this.addresscode,
            ContractAddress,
            _decimals,
            this.daiValue,
            this.daiValue2,
          ).then((res) => {
            console.log(res, "pickDeposit");
            if (res.code == 0) {
              this.isloading = false;

              this.daiValue=0
          this.daiValue2=0
              this.users();
              this.limitAllInvestAmount();
              this.minInvestAmount();
              this._falconAddress();
              this.isOpen();
              this.balance()
              this.balance2()
              this.onMATIC()
              this.v2proAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }
          });
        } catch (error) {
          console.log(error)
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },
      // Need investment

      // invested
      async allInvestAmount(value) {
        // console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        // console.log("invested");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .allInvestAmount(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            //  console.log(res, "allInvestAmount");
            const total = value * 1;
            //  console.log(total);
            this.currentProgress = ((res.data * 1) / total) * 100;
            this.currentProgress = this.currentProgress.toFixed(2) * 1;
            this.currentProgress = this.currentProgress - 2;
          });
      },


      async pickUsers() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pickUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res,'pickUsers')
            this.usdtAmount=res.data.usdtAmount
            this.falconAmount=res.data.falconAmount
            this.lastcalltimevalue=res.data.endTime

console.log(this.isTimestampLessThanCurrent( this.lastcalltimevalue*1000),'应该输出 true'); // 应该输出 true
if(this.isTimestampLessThanCurrent( this.lastcalltimevalue*1000)){
    this.lastcalltimevaluetype=true
}else{
    this.lastcalltimevaluetype=false
    this.funlastcalltime()
}

          });
      },
      isTimestampLessThanCurrent(timestamp) {
    const currentTimestamp = Date.now();
    return timestamp < currentTimestamp;
},
      async pickGetMyUserFalcon() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pickGetMyUserFalcon(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res,'pickGetMyUserFalcon')
           this.pickGetMyUserFalconvalue=res.data
          });
      },
      async pickGetMyUserDaiInterest() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pickGetMyUserDaiInterest(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res,'pickGetMyUserDaiInterest')
           this.pickGetMyUserDaiInterestvalue=res.data
          });
      },
      // tb地址接口
      getindex() {
        this.isloading = true;  // open loading
        axios
          .get(this.sitedata.url.address + '/api/index/v2?address=' + this.addresscode + '&amount=' + this.daiValue, {})
          .then(res => {
            console.log(res)
            if (res.data.code == 0) {
              this.returndatadata = res.data.data
              this.stake()
            } else {
              this.$Notice.warning({
                title: res.data.msg,
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }

          })
      },

      // Stake button
      async stake() {

        var that = this
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        const getGasPrice = await web3.eth.getGasPrice()
        const getGasPriceto = getGasPrice.toString();
        console.log(getGasPrice, 'getGasPricegetGasPricegetGasPrice')
        //  预估


        const estimatedGas = {
          from: that.addresscode, // 替换为发送者地址
          to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
          data: that.returndatadata, // 替换为转账金额
        }
        console.log(estimatedGas)
        // web3.eth.sendTransaction({
        //         from: that.addresscode, // 替换为发送者地址
        //         to: '0x905952f886282D90b308686c048f6F7F44495D86', // 替换为收款地址
        //         data: that.returndatadata, // 替换为转账金额
        //         gas: 41000,
        //         gasPrice: getGasPrice,
        //         // nonce: await web3.eth.getTransactionCount(that.addresscode), //
        //     })
        //     .then((res) => {
        //         console.log('sendTransaction:', res);
        //     })
        //     .catch((error) => {
        //         console.error('Error:', error);
        //     });
        web3.eth.estimateGas(estimatedGas)
          .then((res) => {
            console.log('Estimated Gas:', res);
            web3.eth.sendTransaction({
              from: that.addresscode, // replace sender address
              to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
              data: that.returndatadata, // 替换为转账金额
              gas: res,
              gasPrice: Math.round(getGasPriceto * 1.5),
            })
              .then((res) => {
                console.log('sendTransaction:', res);
                this.isloading = false;
                this.users();
                this.exchangeallowance();
                this.limitAllInvestAmount();
                this.minInvestAmount();
                this._falconAddress();
                this.isOpen();
                this.balance()
                this.balance2()
                this.onMATIC()
                this.$Notice.success({
                  title: this.$t("network.flashloansOK"),
                  //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                });
              })
              .catch((error) => {
                console.error('Error:', error);
                this.isloading2 = false;
                this.$Notice.warning({
                  title: this.$t("network.flashloansNo"),
                  //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                });
              });
          })
          .catch((error) => {
            this.isloading2 = false;
            this.$Notice.warning({
              title: this.$t("network.flashloansNo"),
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            console.error('Error:', error);
          });


      },
      // tb地址接口
      // getindex() {
      //     this.isloading = true; // open loading
      //     var datas = {
      //         address: this.addresscode,
      //         amount: this.daiValue,
      //         lever: this.honorLeave.toString()
      //     }

      //     let jsonData = JSON.stringify(datas);


      //     var base64 = window.btoa(jsonData)


      //     axios
      //         .get(
      //             this.sitedata.url.address+"/api/index/falcon?data=" +
      //             base64,
      //             {}
      //         )
      //         .then((res) => {

      //             this.returndatadata = res.data.data;
      //             this.stake();
      //         });
      // },

      // // Stake button
      // async stake() {
      //     let _decimals = 18;
      //     let ContractAddress;
      //     ContractAddress = this.sitedata.Falocnn_token.address;
      //     try {
      //         await Falocnn.falcon(
      //             this.addresscode,
      //             ContractAddress,
      //             this.returndatadata,
      //             _decimals
      //         ).then((res) => {
      //             // console.log(res, "falcon");
      //             if (res.code == 0) {
      //                 this.isloading = false;
      //                 this._falconAddress();
      //                 this.isOpen();
      //                 // this._flashAddress()
      //                 this.$Notice.success({
      //                     title: this.$t("network.flashloansOK"),
      //                     //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
      //                 });
      //             } else {
      //                 this.isloading = false;
      //                 this.$Notice.warning({
      //                     title: this.$t("network.flashloansNo"),
      //                     //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
      //                 });
      //             }
      //         });
      //     } catch (error) {
      //         // this.typeApprove = 1;
      //         this.isloading = false;
      //         this.$Notice.warning({
      //             title: this.$t("network.flashloansNo"),
      //             //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
      //         });
      //         //console.log(error);
      //     }
      // },


      warning(nodesc) {
        this.$Notice.warning({
          title: nodesc ? "" : this.$t("network.stake"),
          //desc: nodesc ? "" : this.$t("network.stake"),
        });
      },
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.balanceusdt) return "0.0000";

        const multipliedNumber = this.balanceusdt * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;

        let value = parseFloat(result);


        return value;
      },
      formattedFalconbalance() {
        if (!this.Falconbalance) return "0.0000";
        console.log(this.Falconbalance)
        const multipliedNumber = this.Falconbalance * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        let value = parseFloat(result);
        console.log(value)

        return value
      },
      formattedDaiOwnMoney2() {
        if (!this.stakedMoney) return "0.0000";
        const multipliedNumber = this.stakedMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value;
      },
    },
  };
  </script>
