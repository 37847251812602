<template>
  <div class="marquee-container">
    <div class="marquee" :style="{ animationDuration: duration + 's' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarQuee",
  props: {
    duration: {
      type: Number,
      default: 10,
    },
  },
};
</script>

<style scoped>
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee {
  display: inline-block;
  padding-left: 100%;
  animation: marquee linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
