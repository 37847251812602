const pt = {
  network: {
    tips: `A rede selecionada não é suportada atualmente!
    Por favor, selecione a rede Matic (Polygon)!`,
    ev: `Por favor, verifique se instalou algum software de carteira, como MetaMask, 
    Trust Wallet, imToken ou TokenPocket.`,
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">Este DAPP não é legalmente protegido em certos países e regiões. Por favor, use com cautela!</strong></h3>
    <br/>
    <p><strong>Prezados Usuários:</strong></p>
<br/>
    <p>Agradecemos sua atenção e suporte ao nosso DAPP. Para garantir seus direitos legais e ajudá-los a entender os riscos legais associados, emitimos o seguinte anúncio importante:</p><br/>

    <p>Este DAPP envolve investimentos e transações com criptomoedas, atividades que podem violar as leis em certos países e regiões. De acordo com as leis locais, regulamentos e diretrizes das autoridades reguladoras, este DAPP não pode operar legalmente em determinados países e regiões. Portanto, declaramos o seguinte:</p>
<br/>
    <h3><strong>1. Restrições de área de serviço:</strong></h3><br/>
    <p style="color:yellow">Este DAPP não oferece serviços de investimento e transações com criptomoedas, nem suporte técnico ou pós-venda, nos seguintes países e regiões:</p><br/>
    <p style="color:yellow">China (incluindo China Continental, Região Administrativa Especial de Hong Kong, Região Administrativa Especial de Macau e Taiwan), Índia, Argélia, Bangladesh, Marrocos, Egito, Nepal, Paquistão, Catar, Iraque, Tunísia, Afeganistão, Bolívia.</p>
   <br/> <strong>Recomendamos que os usuários dos países e regiões mencionados acima interrompam imediatamente o uso deste DAPP para evitar possíveis riscos legais.</strong>
<br/><br/>
    <h3><strong>2. Aviso de riscos legais e de investimento:</strong></h3><br/>
    <p>Qualquer uso deste DAPP para investimentos e transações com criptomoedas nos países e regiões mencionados acima pode resultar em sérias consequências legais. Nossa equipe não assume qualquer responsabilidade legal por tais ações. Além disso, o mercado de criptomoedas é altamente volátil e imprevisível, e as atividades de investimento envolvem riscos significativos. Por favor, certifique-se de compreender completamente os riscos envolvidos antes de decidir se continuará a usar este DAPP para investimentos e transações.</p>
<br/>
    <h3><strong>3. Confirmação do Usuário:</strong></h3><br/>
    <p>Se você continuar a usar este DAPP para investimentos e transações com criptomoedas, será considerado que leu e entendeu completamente o conteúdo deste anúncio, reconhecendo que está ciente e disposto a assumir os riscos legais e de investimento que podem surgir. Todas as ações que você realizar serão consideradas como voluntárias, e a equipe de operação não assumirá qualquer responsabilidade pelas consequências que possam resultar.</p>
<br/>
    <h3><strong>4. Declaração da equipe de operação:</strong></h3><br/>
    <p>Este DAPP foi desenvolvido e é operado pela equipe Bull Developer. Sempre seguimos os princípios da conformidade e nos esforçamos para oferecer serviços de alta qualidade aos usuários. No entanto, devido ao ambiente legal específico em determinados países e regiões, não podemos continuar oferecendo serviços relacionados a criptomoedas nessas áreas. Recomendamos fortemente que todos os usuários entendam e cumpram as leis e regulamentos do seu país ou região antes de usar este DAPP, para evitar possíveis riscos legais e de investimento.</p>
<br/>
    <p><strong>Agradecemos novamente pela sua compreensão e apoio. Se tiver dúvidas ou precisar de assistência adicional, entre em contato conosco através do nosso site oficial.</strong></p>
<br/>
    <p><strong>Desejamos a você tudo de bom!</strong></p>
<br/>
    <p><strong>Equipe Bull Developer</strong></p>`,
    protocol: `Este Aplicativo Distribuído (DApp) apresenta riscos de investimento incertos. Antes de usar, por favor, leia atentamente e compreenda totalmente os avisos de risco e a política de privacidade.`,
    more: "Detalhes",
    media: "Mídias Sociais Relacionadas",
    stake:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    withdraw:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    Reinvest:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    Getreward:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    linkwallet: " Conectar Carteira",
    notification: "O teste público de aceleração de queima do Falcon foi concluído com sucesso, e agora procederemos com a implementação do contrato oficial. Fique atento!",
    flashloansOK:'Inicialização bem-sucedida! ',
    flashloansNo:'Inicialização falhou!',
    flashloansOnce:'Parabéns por receber uma chance de experiência!',
    flashloansError:'Desculpe, a oportunidade de experiência foi utilizada!',
    falocnerror:'Falcon™ V2 Não autorizado para teste!',
    falocninfo:'Falcon™ V2 para jogadores aprovados no nível V3! Atualmente em fase de teste público!!',
    falconv4:'O cluster de arbitragem Falcon™V4 está em teste beta (28000 vezes/dia)!'
  },
  leavel: {
    0:"Semente",
    1: "Groselha-preta",
    2: "Cerejas",
    3: "Pitaia",
    4: "Avelã",
    5: "Noni",
    6: "Caqui",
    7: "Trufa",
  },
  top: {
    Account: "Conta",
  },
  swap:{
    Swap:"Troca",
    paynameA:"Pagamento",
    paynameB:"Recebimento",
    Approve:"Autorização",
    launchSwap:"Iniciar troca",
    recommend:"Recomendação",
    swapError:"Erro de troca",
    swapNetwork:"Erro de rede",
  },
  stake: {
    Balance: "Seu saldo",
    Total: "Aposta total",
    Appoove: "Aprovar",
    Stake: "Aposta",
    Appooveing: "Autorizando",
    AuthorSuccessful: "Autorização Bem-sucedida",
    AuthorFailed: "Falha na Autorização",
    Stakeing: "Depositando",
    StakeSuccessful: "Depósito Bem-sucedido",
    StakeFailed: "Falha no Depósito",
    StakeInfo: "Não pode ser Menor que:",
    Activeaccount:"Ativar conta",
    active:"Ativar"
  },
  menu: {
    Home: "Início",
    Language: "Idioma",
    Document: "Livro Branco",
    Contract: "Contrato",
    Watch: "Assistir",
    Coming: "Em breve",
    EvDao: "BULLDAO",
    Flashloan: "Iniciar Flashloan",
    Developer:"Desenvolvedor",
    Launch:"Launch",
    Falcon:"Falcon™",
    xNumber:"Restante",
    xTips:"Processamento automático",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"O teste público global do Falcon™ V3 começa (pedidos diários de 1000~7000), é necessária autorização para os contratos de arbitragem correspondentes!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"Lucro",
  },
  ido:{
    select: "Selecionar comunidade",
    flashmonkey: "Macaco Flash",
    flashdragon: "Dragão Flash",
    fashFalcon: "Falcão",
    powermonger: "Poder Monger",
    fashfalcon2: "FalconV2",
    other: "Outro",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "Retirar",
    Withdrawable: "Retirável",
    Earnings: "Ganhos",
    Brokerage: "Quantidade de corretagem",
    Reinvest: "Reinvestir",
    Getreward: "Obter recompensa",
    Processing: "Em processamento",
    unsuccessfule: "Não foi bem-sucedido",
    successful: "Bem-sucedido!",
    WithdrawInfo1: "Por favor, insira a quantidade de retirada",
    WithdrawInfo2: "quantidade insuficiente",
    burning:"FalconBurn",
    noBurn: "No Burn",
    addBurn: "Rendimento adicional",
    addBurnCost: "Custo adicional",
    BurnCount: "Conta de queima",
    BurnSpeed: "Aceleração de queima",
    needpay: "Necessário pagar",
    startBurn: "Iniciar aceleração",
    Burning: "Acelerando"
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Aposta da equipe",
    leavel: "Nível",
    Team: "Equipe",
    Bind: "Vincular relação",
    info: "O sistema pausou temporariamente a vinculação, por favor aguarde a abertura.",
    Binding: "Vinculação em Andamento",
    BindingSuccessful: "Vinculação Bem-sucedida",
    BindingFailed: "Falha na Vinculação",
    Bindinfo: "Por favor, insira o endereço da carteira do convidante",
  },
  Share: {
    Invitelink: "Link de convite",
    Intips:
      "Os convites Dapp não estão disponíveis no momento. Aguarde as atualizações mais recentes da comunidade.",
    copyTitle: "Link de convite",
    copyText: "Link de convite copiado com sucesso!",
  },
  Contract: {
    contractaddress: "Endereço do contrato",
    Copylink: "Polygonscan",
    CopylinkText: "Ir para polygonscan!",
    copyCommnad: "Copiar Link",
  },
  Wathch: {
    watchonlywallet: "Carteira somente para assistir",
    addwallet: "Adicionar",
    add: "Adicionar",
    Leavel: "Nível",
    share: "Compartilhar",
    fnOpen:
      "Esta carteira ainda não foi convidada para participar desta funcionalidade!",
      nullString: "Por favor, insira o endereço da carteira!",
      addok:"Adicionado com sucesso",
      addError:"Falha ao adicionar",
      addisExists:"Este endereço já existe",
      addlist:"Lista de Observação",
  },
};
export default pt;
